@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100vw;
  height: 100vh;
}
